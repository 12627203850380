import React from 'react';
import './AlternatingSections.css';

const SECTIONS = [
  {
    title: 'Contract Deployments',
    description: 'Contract Deployment Channel - Get the earliest updates on fresh Ton pairs introduced through our Crypton Deploys Bot channel. The bot will display a banner when the project is deployed and provide an updated one when liquidity is added. Crypton\'s Token Deployment Bot ensures instant notifications for new Tokens/LP Deploys on Telegram\'s official TON Blockchain.\n',
    link: ['https://t.me/crypton_deploys'],
      buttonText: ["Visit Channel"],
    videoSrc: '/animations/deployments.mp4'
  },
  {
    title: 'Buy Bot\n',
    description: 'Your go-to tool for the official Telegram Blockchain, made just for the active TON Community. Stay updated and manage your token buys effortlessly. Available to everyone, making your crypto experience smoother. If you\'re a developer looking for a buybot for your project, check out our buybot channel for setup instructions on the best one for the TON network.',
    link: ['https://t.me/CryptonBuyBot'],
      buttonText: ["Try BuyBot"],
    videoSrc: '/animations/buybot.mp4'
  },
  {
    title: 'TON Trending',
    description: 'Crypton\'s Trending Bot, TON Trending, is your gateway to discovering the newest and hottest tokens making waves on the TON network. Explore the dynamic world of trends through 10 Pools, where real-time updates refresh every 30 seconds. Stay updated on the latest happenings on Ton Chain, ensuring you\'re well-informed about the ongoing trends and changes.\n',
    link: ['https://t.me/tontrending_live'],
      buttonText: ["See Trending Pools"],
    videoSrc: '/animations/trending.mp4'
  },
  {
    title: 'Cryptonite Analyzer\n',
    description: 'Cryptonite Analyzer bot swiftly generates precise analysis reports for TON Chain Tokens. Effortlessly identify potential scams with its user-friendly interface, providing detailed insights into token origins,deployer\'s history, transaction records, and smart contract details.',
    link: ['https://t.me/CryptoniteScannerBot'],
    buttonText: ["Scan Jettons"],
    videoSrc: '/animations/analyzer.mp4'
  },
  {
    title: 'Super Bot\n',
    description: 'Crypton Super Bot is a game-changer for your trading endeavors on the TON blockchain. This highly efficient bot stands out for its remarkable speed and accuracy, redefining how you navigate the market. Specifically designed for executing liquidity snipes, placing limit orders, and acquiring tokens, Crypton Super Bot ensures you\'re always one step ahead. Its lightning-fast transactions not only optimize your trading strategy but also guarantee that you secure the most advantageous deals available.',
    link: ['https://t.me/CryptonSuperBot'],
    buttonText: ["Start Trading"],
    videoSrc: '/animations/sniper.mp4'
  },
  {
    title: 'Wallet Tracker\n',
    description: "The Crypton Wallet Tracker plays a vital role in conducting a comprehensive analysis of any wallet's activity. In future updates, it will be able to detect transaction records, and potential airdropped wallets during launches from dev wallets that you track.",
    link: ['https://t.me/CryptonWalletTrackerBot'],
    buttonText: ["Track Wallets"],
    videoSrc: '/animations/wallet_scanner.mp4'
  }
];

function AlternatingSections() {
  return (
    <div className="alternating-sections" id="alternating-sections">
      <h1 style={{"textAlign": "center", "marginBottom": "50px"}}>Products & Features</h1>
      <div className="wrapper">
        {SECTIONS.map((section, idx) => (
          <div key={`alternating_section_${idx}`} className={`section ${idx % 2 === 0 ? 'text-left' : 'text-right'}`}>
            <div className="text-content">
              <h2>{section.title}</h2>
              <p>{section.description}</p>
              <div className="text-content_links">
                {section.link.map((link, linkIdx) => (
                  <a key={`link_${linkIdx}`} href={link} target="_blank" rel="noopener noreferrer">{section.buttonText[linkIdx]} &rarr;</a>
                ))}
              </div>
            </div>
            <div className="video-content">
              <video src={section.videoSrc} autoPlay muted playsInline loop></video>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AlternatingSections;
