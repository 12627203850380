import React from 'react';
import './Footer.css';




function Footer() {
  return (
      <footer className="footer-container">
        <h1 style={{"text-align": "center", "margin-bottom": "30px"}}>Socials</h1>
        <ul className="footer_icons">
          <li className="footer_icon">
            <a href="https://t.me/cryptonportal"><img src="/icons/telegram.svg" alt="CRYPTON Portal" /></a>
          </li>
          <li className="footer_icon">
            <a href="https://x.com/thetonhub"><img src="/icons/twitter-x.svg" alt="Twitter / X" /></a>
          </li>
          <li className="footer_icon">
            <a href="https://www.geckoterminal.com/ton/pools/EQAUT9cWRM9YNNVo0IB0-CEAuNqNkXRaKzrSlvz-47xm98cR"><img src="/icons/geckoterminal.png" alt="GeckoTerminal Chart" /></a>
          </li>
          <li className="footer_icon">
            <a href="https://www.dextools.io/app/en/ton/pair-explorer/EQAUT9cWRM9YNNVo0IB0-CEAuNqNkXRaKzrSlvz-47xm98cR"><img src="/icons/dextools.svg" alt="DexTools Chart" /></a>
          </li>
          <li className="footer_icon">
            <a href="https://medium.com/@crypton1938/welcome-to-crypton-203857de5aa4"><img src="/icons/medium.svg" alt="Medium" /></a>
          </li>
          <li className="footer_icon">
            <a href="mailto:DevatCrypton@gmail.com"><img src="/icons/gmail.svg" alt="Contact Us" /></a>
          </li>
        </ul>
        <div className="footer_branding-section wrapper">
          <div className="footer_branding-section_left">
            <img src="/logo-bw.png" alt="CRYPTON" />
            <p>CRYPTON - The TON Hub</p>
          </div>
          <div className="footer_branding-section_right">
            <p>© 2024 CRYPTON. All rights reserved.</p>
          </div>
        </div>
      </footer>
  );
}

export default Footer;
