import React, { useState, useEffect } from 'react';
import './RevShare.css';

function RevShare() {
  const [revshareData, setRevshareData] = useState(null);
  const [openWallets, setOpenWallets] = useState(null);

  useEffect(() => {
    const url = "https://crypton-be-bk6w.onrender.com/revshare";
    fetch(url)
      .then(response => response.text())
      .then(backendData => {
          const data = JSON.parse(backendData);
          if (data["error"]) {
              console.error("Error loading data.");
          } else {
              console.log(data);
              setRevshareData(data);
          }
      })
      .catch(error => console.error("Error loading data."));
  }, []);

  const handleOpenWallets = (wallets) => () => {
    setOpenWallets(wallets);
  };

  const LoadingBox = () => (
    <div className="loading-box"></div>
  );

  const WalletPopup = ({closePopup}) => (
      <>
        <div className="wallet-popup-overlay" onClick={closePopup}></div>
        <div className="wallet-popup">
            <h2>Wallets</h2>
          <ul>
            {openWallets.map((wallet, index) => (
              <li key={index} className="wallet-popup-item">
                <a href={`https://tonviewer.com/${wallet}`}>{wallet}</a>
              </li>
            ))}
          </ul>
        </div>
      </>
  );

  return (
    <>
      {openWallets && <WalletPopup closePopup={() => setOpenWallets(null)} />}
      <ul className="revshare_list">
        {revshareData ? (
          <>
            <li className="revshare_item">
              <h2>{revshareData['trending']['total']} TON</h2>
              <p>TON Trending</p>
              <button onClick={handleOpenWallets(revshareData['trending']['wallets'])} className="revshare_wallets-button">See Wallets</button>
            </li>
            <li className="revshare_item">
              <h2>{revshareData['ads']['total']} TON</h2>
              <p>Crypton Ads</p>
              <button onClick={handleOpenWallets(revshareData['ads']['wallets'])} className="revshare_wallets-button">See Wallets</button>
            </li>
            <li className="revshare_item">
              <h2>{revshareData['superbot']['total']} TON</h2>
              <p>Crypton SuperBot</p>
              <a href={`https://tonviewer.com/${revshareData['superbot']['wallet']}`} className="revshare_wallets-button">See Wallet</a>
            </li>
          </>
        ) : (
          <>
            <li className="revshare_item">
              <h2><LoadingBox /></h2>
              <p>TON Trending</p>
              <button disabled className="revshare_wallets-button">See Wallets</button>
            </li>
            <li className="revshare_item">
              <h2><LoadingBox /></h2>
              <p>Crypton Ads</p>
              <button disabled className="revshare_wallets-button">See Wallets</button>
            </li>
            <li className="revshare_item">
              <h2><LoadingBox /></h2>
              <p>Crypton SuperBot</p>
              <button disabled className="revshare_wallets-button">See Wallet</button>
            </li>
          </>
        )}
      </ul>


        <ul className="revshare_list">
            <li className="revshare_item">
              <h2>140,280 TON</h2>
              <p>Total Revenue on Previous Rounds (Until August 23rd)</p>
            </li>
        </ul>


        <ul className="revshare_list">
            <li className="revshare_item no-max-width">
              <h2>EQD36Lxp6p4FMzHQThWzvFNaqhbT8Qip4rMF1NYjCSgY6ksE</h2>
              <p>Contract Address</p>
            </li>
        </ul>
    </>
  );
}

export default RevShare;